@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.image {
  &__wrapper {
    position: relative;
    display: inline-block;
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    width: 100%;
  }
  &__image {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: cover;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
  }
  &__animation {
    position: absolute;
    bottom: -4rem;
    right: 16rem;
    max-width: 500px;
    width: 100%;
    height: auto;
    z-index: -1;
  }
}
