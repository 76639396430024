@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
// Resets
@import 'sanitize.css/sanitize.css';
@import 'sanitize.css/assets.css';
@import 'sanitize.css/forms.css';
@import 'sanitize.css/reduce-motion.css';
@import 'sanitize.css/system-ui.css';
@import 'sanitize.css/typography.css';
@import 'sanitize.css/ui-monospace.css';

// Swiper
@import 'swiper';

// Header menu mobile
body.menu-open {
  @media (max-width: calc($break-md - 1px)) {
    overflow: hidden;
  }
}

.background {
  &__radical {
    color: $white;
    background-color: $black;
    background-image: radial-gradient(circle, $black 0%, $blue 100%);
  }

  &__primary {
    color: $white;
    background-color: $darkBlue;
  }

  &__secondary {
    color: $white;
    background-color: $black;
  }

  &__linear--top {
    color: $white;
    background-color: $black;
    background-image: linear-gradient(0deg, $blue 0%, $black 100%);
  }

  &__linear--bottom {
    color: $white;
    background-color: $black;
    background-image: linear-gradient(0deg, $black 0%, $blue 100%);
  }

  &__transparent {
    background-color: transparent;
  }
}


// Header

h2 {
  line-height: 1.2;
  font-size: 2rem;
  margin-bottom: 0.25em;
}