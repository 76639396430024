@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.section {
  position: relative;
  z-index: 0;

  &__content {
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: var(--font-random-grotesque);
    font-weight: 500;

    h3 {
      font-weight: 700;
    }
  }

  &__align {
    &__top {
      justify-content: flex-start;
    }

    &__center {
      justify-content: center;
    }

    &__bottom {
      justify-content: flex-end;
    }
  }

  &__footer {
    @media (min-width: $breakpoint) {
      max-width: 60%;
    }

    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    text-align: right;

    p {
      font-family: var(--font-random-grotesque);
      font-weight: 500;
      font-size: 0.75rem;
      margin: 0;
    }
  }

  &__background_media {

    [class*='Video_video__wrapper'],
    [class*='Image_image'] {
      opacity: 0.4;
    }
  }
}