@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.casestudy {
  &__hero {
    margin-top: 3rem;
    &__container {
      @include container;
      margin-bottom: 2rem;
    }
    &__eyebrow {
      display: flex;
      flex-direction: column;
      @media (min-width: $break-md) {
        flex-direction: row;
      }
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      margin-top: 6rem;
      &__logos {
        img {
          margin: 0 1rem 0 0;
          width: auto;
          height: 20px;
          @media (min-width: $break-md) {
            height: 30px;
          }
          &:first-child {
            padding-right: 1.5rem;
            border-right: 3px solid rgba($white, 0.75);
          }
        }
      }
      &__techstack {
        display: flex;
        column-gap: 2rem;
      }
    }
    &__title {
      margin-top: 3rem;
      > h1 {
        margin-top: 0;
        margin-bottom: 2rem;
        color: $white;
        font-size: 2.5rem;
        max-width: 800px;
        line-height: 2.5rem;
      }
    }
    &__subtitle {
      > h2 {
        font-weight: 300;
        font-size: 1.5rem;
      }
    }
    &__cardgroup {
      margin: 3rem 0;
      @media (min-width: $break-2xl) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 1rem;
      }
      &__card {
        flex: 0 0 320px;
        margin-top: 2rem;
        
        @media (min-width: $break-2xl) {
          padding: 0 1.8rem;
          &:first-child {
            padding: 0 1.8rem 0 0;
          }
          &:not(:last-child) {
            border-right: 1px solid $lavender;
          }
        }
        &__header {
          margin: 0;
          font-size: 1.25rem;
        }
        &__text {
          font-size: 0.9rem;
          letter-spacing: 1px;
          margin-left: unset;
        }
      }
    }
    &__featureimage {
      width: 100%;
      text-align: center;
      &__image {
        width: 100%;
        max-width: 700px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        margin-bottom: -11rem;
      }
    }
  }
  &__content {
    @include container;
    margin-top: 10rem;
    margin-bottom: 4rem;
    p {
      font-family: $font-body;
      font-size: 1.125rem;
    }
    color: $black;
    [class*='eyebrow'] {
      font-family: var(--font-random-grotesque);
      display: block;
      margin-top: 3rem;
      font-size: 1.25rem;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: -1.75rem;
      color: lighten($black, 60%);
      &::after {
        content: '';
        display: inline-block;
        margin-left: 0.25rem;
        width: 0.5rem;
        border-radius: 50%;
        height: 0.5rem;
        background-color: $pink;
        margin-top: 0.5rem;
      }
    }
  }
}
