@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.hero {
  &__wrapper {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (orientation:portrait) and (max-width: calc($break-lg - 1px)) {
      margin-top: 160px;
      margin-bottom: 80px;
    }

    @media screen and (orientation:landscape) and (max-width: calc($break-lg - 1px)) {
      min-height: 85vh;
      margin-top: 2rem;
    }

    @media screen and (min-width: $break-lg) {
      min-height: 80vh;
    }
  }

  &__animation {
    position: absolute;
    aspect-ratio: 3 / 1;

    @media screen and (orientation:portrait) and (max-width: calc($break-lg - 1px)) {
      right: 1rem;
      bottom: -1rem;
      max-width: 75%;
      max-height: 150px;
    }
  
    @media screen and (orientation:landscape) and (max-width: calc($break-lg - 1px)) {
      right: 0;
      bottom: -1rem;
      max-width: 75%;
      max-height: 150px;
    }

    @media screen and (min-width: $break-lg) and (max-width: calc($break-3xl - 1px)) {
      right: 0;
      bottom: 0;
      width: 1000px;
    }

    @media screen and (min-width: $break-3xl) and (max-width: $break-4xl) {
      right: 0;
      bottom: 0;
      width: 1300px;
    }

    @media screen and (min-width: $break-4xl) and (max-width: $break-5xl) {
      right: 0;
      bottom: 5vh;
      width: 1600px;
    }

    @media screen and (min-width: $break-5xl) {
      right: 5vw;
      bottom: 10vh;
      width: 2000px;
    }
  }

  &__container {
    @include container;
  }

  &__heading {
    color: $white;
    opacity: 0;
    h1,
    h2 {
      font-family: var(--font-random-grotesque);
      font-size: 60px;
      font-weight: 700;
      line-height: 0.9;
      margin: 1rem 0;
      @media (min-width: $breakpoint) {
        font-size: 80px;
        font-weight: 700;
      }
    }
    [class*='text'] {
      padding: 0;
    }
  }

  &__eyebrow {
    font-size: 0.9rem;
    font-family: var(--font-source-code-variable);
    font-weight: 300;
    color: $white;
    &__short {
      float: left;
      margin-top: 2rem;
    }
    &__long {
      margin: 2rem 0;
    }
  }
}
