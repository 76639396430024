@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.slidercoverflow {
  position: relative;
  width: 100%;
  height: 80vh;
  margin-top: 5rem;

  &__slide {
    height: 100%;
    width: auto;
    user-select: none;
    &__image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 1.5rem;
    }
  }
  [class^='react-stacked-center-carousel'] {
    overflow: visible !important;
  }

  [class^='react-stacked-center-carousel-slide-1'] {
    top: -100px;
  }
  [class^='react-stacked-center-carousel-slide-2'] {
    top: -200px;
  }
  [class^='react-stacked-center-carousel-slide--1'] {
    top: 100px;
  }
  [class^='react-stacked-center-carousel-slide--2'] {
    top: 200px;
  }
}
