@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
@keyframes svgAnimation {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 1000;
  }
}

@keyframes glow {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes animateOutline {
  0% {
    outline-width: 1px;
    outline-offset: 0;
    outline-color: rgba(0, 130, 206, 0);
  }

  10% {
    outline-color: var(--fill-color);
  }

  /* The animation finishes at 50% */
  50% {
    outline-width: 7px;
    outline-offset: 4px;
    outline-color: rgba(0, 130, 206, 0);
  }

  100% {
    outline-width: 7px;
    outline-offset: 4px;
    outline-color: rgba(102, 102, 102, 0);
  }
}

.form {
  &__header {
    z-index: 2;
    text-align: center;
    canvas {
      max-height: 100px;
      width: auto !important;
    }
  }
  &__container {
    position: relative;
    @include container;
    max-width: 800px;
    margin: 1rem auto 2rem auto;
    z-index: 1;
  }
  &__content {
    @media (min-width: $breakpoint) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1.25rem;
    }
  }
  &__verify {
    margin: auto;
    text-align: center;
    padding: 1rem;
    color: var(--fill-color)
  }
  &__wrapper {
    margin-top: 2rem;
    position: relative;
    .form__input {
      border-radius: 0.75rem;
      border: 1px solid rgba(186, 214, 247, 0.54);
      z-index: 3;
    }
    input,
    textarea {
      font-size: 1rem;
      padding: 0.75rem;
      color: $white;
    }
    label {
      color: $white;
    }
    &__inner {
      padding: 2rem;
      border-radius: 0.75rem;
      backdrop-filter: blur(7.6px);
      -webkit-backdrop-filter: blur(7.6px);
      &__style1 {
        position: relative;
        overflow: hidden;
        margin: 1px;
        background: rgba($darkBlue, 0.875);
      }
      &__style3 {
        background-color: rgba($darkBlue, 0.65);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      }
    }

    > svg {
      position: absolute;
      rect {
        stroke-dasharray: 460;
        stroke-width: 4px;
        fill: transparent;
        stroke: var(--fill-color);
        animation: svgAnimation 2.5s linear infinite;
      }
    }
    &__style {
      &__style1 {
        border-radius: 0.75rem;
        label {
          color: var(--fill-color);
        }
      }
      &__style2 {
        border: 1px solid var(--fill-color);
        outline-width: 1px;
        outline-offset: 0;
        outline-color: var(--fill-color);
        border-radius: 0.75rem;
        outline-style: solid;
        animation: animateOutline 4s ease infinite;
        label {
          color: var(--fill-color);
        }
      }

      &__style3 {
        box-shadow:
          1px 3px rgba(0, 0, 0, 0.05) inset,
          0px 0px 8px rgba(82, 168, 236, 0.6);
        border-radius: 0.75rem;
        backdrop-filter: blur(7.6px);
        -webkit-backdrop-filter: blur(7.6px);
        padding: 4px;
        z-index: 1;
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: linear-gradient(
            45deg,
            $pink,
            $blue,
            $lavender,
            $orange
          );
          background-size: 400%;
          z-index: -1;
          animation: glow 20s linear infinite;
          width: 100%;
          border-radius: 0.75rem;
        }
        &::after {
          filter: blur(25px);
          transform: translate3d(0, 0, 0); /* For Safari */
        }
        label {
          color: var(--fill-color);
        }
      }
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1.25rem;
    width: 100%;
  }
  &__input {
    position: relative;
    z-index: 0;
    width: 100%;
    margin: 1rem 0;
    &__error {
      color: var(--fill-color);
      font-size: 0.85rem;
    }

    &__grow {
      height: 100%;
      > input,
      textarea {
        min-height: 6rem;
        height: 100%;
      }
    }

    input::placeholder {
      opacity: 0;
    }

    input,
    textarea {
      width: 100%;
      border-radius: 0.25rem;
      box-sizing: border-box;
      border: none;
      position: relative;
      font-family: var(--font-source-code-variable);
      font-weight: 600;

      &:focus {
        border: none;
        outline: none;
      }
    }

    label {
      position: absolute;
      left: 10px;
      top: 1.5rem;
      transform: translateY(-50%);
      transition: 0.3s;
      font-family: var(--font-source-code-variable);
      font-weight: 600;
    }

    input:focus + label,
    textarea:focus + label,
    input:not(:placeholder-shown) + label,
    textarea:not(:placeholder-shown) + label {
      top: 0;
      font-size: 0.75rem;
      color: var(--fill-color);
      background-color: $white;
      padding: 0.125rem 0.5rem;
      border-radius: 4px;
    }
  }
  &__submit {
    margin-top: 2rem;
    border: none;
    color: $orange;
    &:hover {
      color: darken($orange, 5);
      cursor: pointer;
    }
    button {
      margin-right: unset;
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &__controls {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1.5rem;
    background: rgba($black, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    backdrop-filter: blur(7.6px);
    -webkit-backdrop-filter: blur(7.6px);
    border: 1px solid rgba(186, 214, 247, 0.54);
    &__color {
      color: $white;
      background: var(--fill-color);
      width: 2rem;
      height: 2rem;
      padding: 0.25rem;
      margin: 0.25rem 0.5rem;
      &:hover {
        cursor: pointer;
      }
    }
    &__container {
      height: 100px;
      margin: 20px;
    }
    select, button {
      border-radius: 5px;
    }
    select {
      color: $white;
      background-color: var(--fill-color);
    }

  }
}
