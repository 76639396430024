@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 40px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.text {
  &__animate {
    opacity: 0;
  }
  &__inview {
    animation: fadeInUp 0.25s ease-in-out 0.25s forwards;
  }

  &__highlight {
    color: $orange;
  }

  &__align {
    &__left {
      text-align: left;
    }
    &__center {
      text-align: center;
    }
    &__right {
      text-align: right;
    }
    &__justify {
      text-align: justify;
    }
  }

  a {
    @include reset-link;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    >span.eyebrow {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      &::after {
        content: '';
        display: block;
        width: 3rem;
        border-radius: 50%;
        height: 0.25rem;
        background-color: $orange;
        margin-top: 0.5rem;
      }
    }
  }

  blockquote {
    position: relative;
    width: 900px;
    line-height: 1;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 3rem;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    padding: 0;
    color: $orange;

    &:before,
    &:after {
      position: absolute;
      color: rgba($orange, 0.5);
      font-size: 8rem;
      width: 4rem;
      height: 4rem;
    }

    &:before {
      content: '“';
      left: -5rem;
      top: -2rem;
    }

    &:after {
      content: '”';
      right: -3rem;
      bottom: -2rem;
    }
  }
}

.image {
  float: left;
  margin-right: 1.25rem;
}
