@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.card {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: var(--font-random-grotesque);
  font-weight: 500;
  border-radius: 0.75rem;
  padding: 1rem;
  width: 100%;
  text-decoration: none;
  color: $orange;
  margin-bottom: 0.75rem;

  &__heading {
    font-size: 2rem;
    line-height: 0.75;
    text-decoration: none;
    margin: 0;
  }

  &__border {
    &__primary {
      border: 2px solid $orange;
    }
    &__secondary {
      border: 2px solid $pink;
    }
    &__lavender {
      border: 2px solid $lavender;
      box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.05) inset,
        0px 0px 8px rgba(82, 168, 236, 0.6);
    }
  }

  &__background {
    &__primary {
      background-color: $orange;
      color: $white;
    }
    &__secondary {
      background-color: $pink;
      color: $white;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
  }
}
