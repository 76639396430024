@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.duplex {
  &__wrapper {
    @include container;
    @media (max-width: $breakpoint) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    @media (min-width: calc($breakpoint + 1px)) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  @media (min-width: 500px) {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__column {
      flex: 1 1 50%;
    }
  }

  &__column__hidden__mobile {
    @media (max-width: $breakpoint) {
      display: none;
    }
  }
}
