@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.video {
  &__wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
    @media (orientation: portrait) {
      padding-top: 178%; /* 1280 / 720 = 1.7777 */
    }
  }

  &__player {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    // for Storyblok preview
    min-width: 240px;
    min-height: 135px;
  }
}
