@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
$slideBorderRadius: 2rem;
@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 40px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.sliderportfolio {
  opacity: 0;

  &__inview {
    animation: fadeInUp 0.25s ease-in-out 0.25s forwards;
  }
  &__wrapper {
    position: relative;
  }
  &__slide {
    &__wrapper {
      position: relative;
      border-radius: $slideBorderRadius;
      box-shadow: 0 4px 40px rgba($lavender, 1);
      margin: 2rem;
      background-size: cover;
      background-attachment: fixed;
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    &__text {
      &__dark {
        color: $black;
        svg {
          fill: $black;
        }
      }
      &__light {
        color: $white;
        svg {
          fill: $white;
        }
      }
    }
    &__background {
      background-color: rgb(from var(--fill-color) r g b / 1);
      border-radius: $slideBorderRadius;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-size: cover;
      background-attachment: fixed;
      img {
        border-radius: $slideBorderRadius;
        object-fit: cover;
      }
    }

    &__header {
      background-color: rgb(from var(--fill-color) r g b / 0.94);
      box-shadow: 0 1px 10px rgba($black, 1);
      padding: 0.5rem 1rem;
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 620px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
      border-radius: 1rem;
      flex-direction: column;
      @media (min-width: $break-sm) {
        flex-direction: row;
        margin-left: 1rem;
        margin-right: 1rem;
      }

      &__tech {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 0.5rem;
        svg {
          height: 45px;
          width: auto;
        }
      }
    }

    &__content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 1rem;
      // margin-right: 2rem;
      @media (max-width: $break-lg) {
        flex-direction: column;
        margin-right: 1rem;
        margin-left: 1rem;
      }
      > div:first-child {
        transform: skew(0turn, 1deg);
      }
      > div:last-child {
        transform: skew(0turn, -1.5deg);
      }
    }

    &__portfolio {
      align-content: center;
      height: 100%;
      @media (min-width: $break-2xl) {
        max-width: 600px;
      }
      @media (max-width: $break-2xl) {
        max-width: 600px;
      }
      @media (max-width: $break-xl) {
        max-width: 450px;
      }
      @media (max-width: $break-lg) {
        max-width: 600px;
      }
    }

    &__review {
      background-color: rgb(from var(--fill-color) r g b / 0.94);
      box-shadow: 0 1px 10px rgba($black, 1);
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      display: inline-flex;
      flex-direction: column;
      height: max-content;
      padding: 1rem;
      border-radius: 1rem;

      &__title {
        margin: 0;
      }

      &__content {
        // this class isn't coming through
        font-style: italic;
        padding-left: 0;
        padding-right: 0;
        [class*='_text'] {
          p {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      &__company {
        margin-top: 0.5rem;
      }

      &__author {
      }

      &__position {
      }
    }

    &__cta {
      max-width: 500px;
      margin: 1rem auto 1rem 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      column-gap: 0.5rem;
      row-gap: 0.25rem;
      svg {
        height: 1rem;
        width: auto;
      }
      button {
        z-index: 2;
      }
    }
  }
}
