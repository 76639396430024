@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.faq {

  &__container {
    @include container;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  &__item {
    font-family: var(--font-random-grotesque);
    font-weight: 500;
    color: $orange;
    border-bottom: 1px dashed $lavender;
    padding: 1rem 0;
    font-size: 1rem;

    &__question {
      font-size: 1.5rem;
    }

    button {
      padding: 0;
      cursor: pointer;
      border: 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      gap: 0.8rem;

      small {
        text-wrap: nowrap;
        margin-top: 0.25rem;
        margin-bottom: auto;
      }
    }

    &__icon {
      color: $orange;
      height: 1rem;
      min-width: 1rem;
      margin-left: auto;

      &__expanded {
        transform: rotate(-30deg);
      }
    }

    &__panel {
      padding: 1rem;
      border-radius: 0.25rem;
      margin-top: 1rem;
      margin-bottom: 0;

      & div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        > p {
          margin-bottom: 0;
        }
      }

      b,
      strong {
        display: block;
      }
    }

    &__enter {
      transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform;
      transition-duration: 300ms;
      transition-timing-function: ease-in;
    }

    &__enterFrom {
      transform-origin: top;
      transform: scaleY(0);
    }

    &__leaveFrom {
      transform-origin: top;
      transform: scaleY(1);
    }
    &__leave {
      transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform;
      transition-duration: 250ms;
      transition-timing-function: ease-out;
    }
  }
}
