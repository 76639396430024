@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.acknowledgement {
  &__wrapper {
    font-size: 1rem;
    color: $white;
    background-color: $black;
    border-top: 4px solid $orange;
    border-bottom: 1px solid $orange;
  }
  &__container {
    @include container;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: $font-heading;
  }
  &__text {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
    &__highlight {
      font-weight: 600;
    }
  }

  &__credit {
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    font-size: 0.75rem;
    font-weight: 400;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

.footer {
  &__wrapper {
    position: relative;
    font-size: 1.5rem;
    font-weight: 400;
    color: $white;
    background-color: $black;
    z-index: 12;
  }

  &__container {
    @include container;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media (min-width: $break-md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__logo {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__copyright {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem; // keeps menu items on same horizontal line due to icon padding
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 1em;

    &__icon {
      display: flex;
      padding: 0.5em;
      color: $white;
      background-color: $orange;
      border-radius: 50%;
    }

    a {
      white-space: nowrap;
      @include reset-link;
    }
  }
}