@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.dots {
  z-index: 1;
  position: absolute;
  top: 30%;
  left: 0;
  height: 30vh;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' fill-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2' clip-rule='evenodd' viewBox='0 0 28 28'%3E%3Ccircle cx='6.357' cy='5.593' r='2.391' fill='%23cc7d3b' transform='translate(-4.64289 -3.36309) scale(1.67421)'/%3E%3C/svg%3E%0A");
  background-repeat: repeat;
  background-size: 1vh;
  animation: fadeIn 5s infinite alternate;
}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}
