@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.page {
  color: $white;
  &__scroll {
    @media (min-height: calc($breakheight + 1px)) {
      overflow: auto;
      height: 100vh;
      max-width: 100%;
      scroll-snap-type: y mandatory;
      > section {
        scroll-snap-align: start;
        &:not(:first-of-type) {
          z-index: 2;
        }
      }
    }
  }
  section:not(:last-of-type):not(:first-of-type) {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
}
