@use 'sass:color';
@import 'swiper/scss';
@import 'swiper/scss/a11y';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

// Should be imported via `_variables.scss`
$buttonColor: $orange;

:root {
  --swiper-navigation-size: 1rem;
  --swiper-pagination-color: #d90000; // @TODO: Refactor to use sccs variable
  --swiper-pagination-bullet-inactive-color: #eef0f2; // @TODO: Refactor to use scss variable
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

.swiper-wrapper {
  align-items: stretch;
}

// Equal Height
.swiper-slide {
  display: flex;
  height: auto;
}

.swiper-button-next,
.swiper-button-prev {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: color.adjust($buttonColor, $alpha: -0.5);
  color: var(--swiper-pagination-color);
  background-position: center;
  font-size: 10px;
  z-index: 10;

  &:hover {
    background-color: $buttonColor;
  }
}

.swiper-button-prev {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50px / 44 * 27);
  height: calc(50px / 44 * 27);
  border-radius: 50%;
  background-color: $orange;
  color: white;
  font-weight: bold;
}

.swiper-button-next {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50px / 44 * 27);
  height: calc(50px / 44 * 27);
  border-radius: 50%;
  background-color: $orange;
  color: white;
  font-weight: bold;
}

.swiper-button-next:after {
  --swiper-navigation-size: 7.5px;
}

.swiper-button-prev:after {
  --swiper-navigation-size: 7.5px;
}

.mySwiper > div:nth-child(1) {
  margin-bottom: 6%;
}

.swiper-button-prev svg,
.swiper-button-next svg {
  width: 100%;
  height: 100%;
  object-fit: none;
  transform-origin: center;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination-progressbar {
  position: absolute;
}
