/**
* MIXINS
*
* Mixins contain consistent styling groups for re-use across
* stylsheets.
*/

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin reset-link {
  color: inherit;
  text-decoration: none;
}

@mixin button-style($color, $border, $darkBlue) {
  color: $color;
  border-color: $border;
  background-color: $darkBlue;
}

@mixin container {
  max-width: $container;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: calc($container + ($gutter * 2))) {
    padding-left: $gutter;
    padding-right: $gutter;
  }
}
