@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.employee {
  &__wrapper {
    position: relative;
    flex-shrink: 0;
    margin: 20px;
    height: calc(100px + 3vw);
    width: calc(100px + 3vw);
    border: calc(3px + 0.3vw) solid;
    background-size: cover;
    box-sizing: border-box;
    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.05) inset,
      0px 0px 4px rgba(82, 168, 236, 0.5);
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba($orange, 0.29);
    @media (min-width: $break-xs) {
      height: calc(100px + 3vw);
      width: calc(100px + 3vw);
      border: calc(3px + 0.3vw) solid;
    }
    @media (min-width: $break-sm) {
      height: calc(130px + 3vw);
      width: calc(130px + 3vw);
      border: calc(3px + 0.3vw) solid;
    }
    @media (min-width: $break-md) {
      height: calc(150px + 6vw);
      width: calc(150px + 6vw);
      border: calc(3px + 0.3vw) solid;
    }
  }

  &__image {
    position: relative;
    height: 100%;
    width: auto;
    margin-left: 1.5rem;
    @media (min-width: $break-sm) {
      margin-left: 2.5rem;
    }
    @media (min-width: $break-md) {
      margin-left: 3.5rem;
    }
  }

  &__detail {
    z-index: 3;
    display: inline-block;
    border-radius: 0.75rem;
    border: 2px solid $orange;
    background-color: rgba($blue, 0.4);
    padding: 0.25rem 0.5rem;
    min-width: 124px;
    @media (max-width: $break-sm) {
      bottom: -3rem;
      right: 0;
    }
    @media (min-width: calc($break-sm + 1px)) {
      bottom: -1rem;
      right: 0;
    }
  }

  &__name {
    font-size: 1rem;
    line-height: 1;
  }

  &__position {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    line-height: 1;
    max-width: 5.2rem;
    margin-bottom: 0.5rem;

    &::before,
    &::after {
      display: inline-flex;
      content: '';
      background-image: url('./bracket.svg');
      background-size: 1rem 1.5rem;
      height: 1.5rem;
      width: 1.5rem;
    }

    &::after {
      transform: rotate(180deg);
    }
  }
}

.transition {
  &__initial {
    opacity: 0;
    transform: scale(0.95);
  }
  &__visible {
    opacity: 1;
    transform: scale(1);
  }
  &__enter {
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  &__leave {
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.25);
}

.dialog {
  position: relative;
  z-index: 10;
  font-family: var(--font-random-grotesque);

  &__container {
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__wrapper {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    text-align: center;
  }

  &__panel {
    overflow: hidden;
    padding: 1.5rem;
    border-radius: 1rem;
    width: 100%;
    max-width: 28rem;
    text-align: left;
    vertical-align: middle;
    color: $white;
    background-color: rgba($black, 0.97);
    border: 1px solid $orange;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  &__close {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: 1rem;
  }

  h3 {
    font-weight: 700;
  }

  p {
    font-family: var(--font-random-grotesque-slim);
    font-weight: 300;
  }
}
