@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.navbar {
  &__wrapper {
    width: 100%;
    z-index: 12;
    display: none;
    align-items: center;

    &__open {
      z-index: 13;
    }

    // scroll-up sticky behavior
    &__isvisible {
      transition: all 0.3s ease-out;
      height: $navbar-height;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
    }

    &__hasbackground {
      background-color: $darkBlue;
    }
  }

  &__container {
    @include container;
    max-width: 1444px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    margin-right: 0.75rem;

    &__link {
      display: flex;
      align-items: center;
      text-decoration: none;
    }
  }

  &__title {
    align-self: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: $orange;
    text-decoration: none;
    text-transform: uppercase;
    width: 10rem;
  }

  &__desktop {
    &__menu {
      display: flex;
      gap: 1rem;

      @media (max-width: calc($break-md - 1px)) {
        display: none;
      }

      &__item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-family: var(--font-random-grotesque);
        font-weight: 700;
        color: $orange;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
          color: darken($orange, 10);
        }

        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }

  &__menu {
    @media (min-width: $break-md) {
      display: none;
    }

    border: none;
    cursor: pointer;

    &__hamburger {
      color: $pink;
      width: 2.5rem;
      height: 2.5rem;

      &__open {
        color: $orange;
      }
    }

    &__items {
      position: absolute;
      z-index: 12;
      width: 100%;

      @media (max-width: calc($break-md - 1px)) {
        right: 0;
        height: 100vh;
        padding-top: calc($navbar-height + 40px);
      }

      >div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      transform-origin: top right;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.6rem 1.5rem;
      width: 100%;
      font-size: 2rem;
      line-height: 1.5rem;
      font-family: var(--font-random-grotesque);
      font-weight: 700;
      color: $orange;
      text-decoration: none;
      text-transform: uppercase;

      &__active,
      &:hover {
        color: darken($orange, 10);
      }

      svg {
        height: 2rem;
        width: 2rem;
      }
    }
  }
}

.transition {
  &__initial {
    opacity: 0;
  }

  &__visible {
    opacity: 1;
  }

  &__enter {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 600ms;
  }

  &__leave {
    transition: none;
  }
}