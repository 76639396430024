@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
$space: calc($navbar-height * 1.5);
.border {
  width: 60%;
  position: absolute;
  z-index: -1;

  &__circle {
    fill: $lavender;
    opacity: 0;
    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.05) inset,
      0px 0px 8px rgba(199, 4, 209, 0.3);
  }

  &__start {
    bottom: $space;
    right: 0;
  }

  &__end {
    top: $space;
    left: 0;
  }
}
