@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.slide {
  @media (max-width: $breakpoint) {
    padding: 1rem;
  }
  @media (min-width: calc($breakpoint + 1px)) {
    padding: 2.5rem;
  }
  border-radius: 1rem;
  border: 1px solid $blue;
  background-color: rgba(186, 214, 247, 0.03);
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.05) inset,
    0px 0px 8px rgba(82, 168, 236, 0.6);
  margin: 0.9rem;

  &__image {
    height: 6rem;
    width: 6rem;
  }
}
