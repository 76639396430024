@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.header {
  &__wrapper {
    @include container;
    display: flex;
    color: $orange;
    font-family: var(--font-source-code-variable);
    line-height: 1.2rem;
    text-transform: uppercase;
  }

  &__image {
    order: -1;
    margin-right: 1.25rem;
  }

  &__h1 {
    font-size: 3rem;
    font-weight: 800;
    line-height: 2.9rem;
  }
}
