// Global colors
$white: #ffffff;
$black: #0d0d0d;
$orange: #F26324;
$pink: #c704d1;
$darkBlue: #040453;
$lavender: #4c26ff;
$blue: #0001c9;

// Sizes
$base-size: 20px;
$break-xs: 420px;
$break-sm: 640px;
$break-md: 768px;
$break-lg: 1024px;
$break-xl: 1280px;
$break-2xl: 1536px;
$break-3xl: 1920px;
$break-4xl: 2560px;
$break-5xl: 3840px;
$gutter: 20px;
$navbar-height: 80px;
$container: 1344px;
$breakpoint: $break-lg;
$breakheight: 719px;

$font-heading: var(--font-random-grotesque);
$font-body: 'DM Sans';

// Heading S
$font-desktop-heading-s-family: $font-heading;
$font-desktop-heading-s-size: 20px;
$font-desktop-heading-s-line-height: normal;
$font-desktop-heading-s-letter-spacing: 0%;

// Mobile Heading S
$font-mobile-heading-s-family: $font-heading;
$font-mobile-heading-s-size: 18px;
$font-mobile-heading-s-line-height: normal;
$font-mobile-heading-s-letter-spacing: 0%;

// Mobile Body S
$font-mobile-body-s-family: $font-body;
$font-mobile-body-s-size: 16px;
$font-mobile-body-s-line-height: 24px;
$font-mobile-body-s-letter-spacing: 0%;
$font-mobile-body-s-para-spacing: 12px;

// Heading M
$font-desktop-heading-m-family: $font-heading;
$font-desktop-heading-m-size: 24px;
$font-desktop-heading-m-line-height: normal;
$font-desktop-heading-m-letter-spacing: 2%;

// Mobile Heading M
$font-mobile-heading-m-family: $font-heading;
$font-mobile-heading-m-size: 20px;
$font-mobile-heading-m-line-height: normal;
$font-mobile-heading-m-letter-spacing: 2%;

// Desktop Body M
$font-desktop-body-m-family: $font-body;
$font-desktop-body-m-size: 18px;
$font-desktop-body-m-line-height: 28px;
$font-desktop-body-m-letter-spacing: 0%;
$font-desktop-body-m-para-spacing: 12px;
