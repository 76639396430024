@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.tawk {
  &__button {
    z-index: 11;
    height: 4.5rem;
    width: 4.5rem;
    color: $pink;
    position: fixed;
    bottom: 0.5rem;
    right: 1rem;
  }
}
