@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.button {
  &__align {
    &__left {
      text-align: left;
    }

    &__right {
      text-align: right;
    }

    &__centre {
      text-align: center;
      width: 90vw;
      margin: auto;
    }
  }

  &__item {
    font-size: 1.25rem;
    font-family: var(--font-random-grotesque);
    font-weight: 700;
    min-height: 45px;
    min-width: 200px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    border-radius: 1rem;
    margin-right: 0.5rem;
    padding: 0 1rem;
  }

  &__style {
    &__primary {
      @include button-style($black, $orange, $orange);

      &:hover {
        @include button-style(
          $black,
          darken($orange, 10),
          darken($orange, 10)
        );
      }
    }
    &__unique {
      background-color: var(--fill-color);
      @include button-style($black, var(--fill-color), var(--fill-color));
    }
  }
}
