@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.particles {
  &__wrapper {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;

    canvas {
      padding-top: 30vh;
    }
  }
}
