@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 40px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.employeegroup {
  &__container {
    opacity: 0;
    color: $orange;
    font-family: var(--font-random-grotesque);
    margin-top: 0;
    display: grid;
    &__inview {
      animation: fadeInUp 0.25s ease-in-out 0.25s forwards;
    }
    @media (max-width: $break-xs) {
      grid-auto-rows: auto;
      grid-template-columns: repeat(auto-fit, 120px);
      column-gap: 1.25rem;
    }
    @media (min-width: calc($break-xs + 1px)) and (max-width: $break-sm) {
      grid-auto-rows: auto;
      grid-template-columns: repeat(auto-fit, 120px);
      column-gap: 3rem;
    }
    @media (min-width: calc($break-sm + 1px)) {
      grid-auto-rows: auto;
      grid-template-columns: repeat(auto-fit, 240px);
      column-gap: 7vw;
    }
    @include container;
    align-items: end;
    justify-content: center;
  }
}
