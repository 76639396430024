@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.blurbicon {
  display: flex;
  align-items: flex-start;
  color: $orange;
  font-family: var(--font-random-grotesque);
  font-weight: 500;
  border-radius: 0.75rem;
  border: 1px solid $white;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.05) inset,
    0px 0px 8px rgba($lavender, 0.7);
  padding: 1.25rem;
  margin-bottom: 1.25rem;

  &__heading {
    font-size: 1.5rem;
    line-height: 1;
  }

  &__image {
    float: left;
    margin-right: 1.25rem;
  }

  &__text {
    width: 100%;

    ul {
      padding-left: 1rem;
      li {
        p {
          margin: 0;
          font-weight: 600;
        }
      }
    }
  }
}
