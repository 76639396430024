@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.reviewSlider {
  &__swiper {
    &__section {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__container {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-width: 1300px;
    }
  }

  &__wrapper {
    width: 1200px;
  }

  // Inner Container to Fix Shadow Cutting Off
  &__container {
    display: flex;
    align-items: flex-start;
    padding: 0.5rem;
  }

  &__content {
    color: $white;
    p:first-child {
      margin-top: 0rem;
    }

    p {
      font-size: $font-desktop-body-m-size;
      line-height: $font-desktop-body-m-line-height;
      color: $black;

      @media (max-width: $break-lg) {
        font-size: $font-mobile-body-s-size;
        line-height: $font-mobile-body-s-line-height;
      }
    }

    &__wrapper {
      background-color: rgba($orange, 0.29);
      box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.05) inset,
        0px 0px 8px rgba(82, 168, 236, 0.6);
      border: 2px solid $orange;
      border-radius: 1.25rem;
      padding: 2.375rem;
      display: flex;
      flex-direction: column;

      @media (max-width: $break-lg) {
        padding: 1.5rem;
      }
    }
  }

  &__author {
    color: $white;
    font-size: $font-desktop-heading-m-size;
    letter-spacing: $font-desktop-heading-m-letter-spacing;
    margin-top: 2rem;

    @media (max-width: $break-lg) {
      font-size: $font-mobile-heading-m-size;
      letter-spacing: $font-mobile-heading-m-letter-spacing;
    }
  }
}
